import { createStore } from "vuex";

export default createStore({
  state: {
    user_credentials: {
      account_no : null,
      ask_otp : null,
      email : null,
      hash : null,
      key : null,
      migs : null,
      mobile : null,
      name : null,
      password : null,
      qr : null,
      serial_no : null,
      timestamp : null,
      username : null,
      uuid : null,
      logged:false,
    }
  },
  getters: {},
  mutations: {},
  actions: {},
  modules: {},
});
