<template>
<div v-show="show" class="w-full h-screen bg-translucent left-0 top-0 z-50 transition delay-100 duration-500 ease-in-out flex flex-col p-2 absolute" v-bind:class="[{'-translate-x-0' : this.popUp ,'-translate-y-full':!this.popUp}]">
     <div class="p-3 text-md bg-translucent font-bold flex flex-row relative justify-end">
        <div class="text-2xl cursor-pointer bg-white rounded-full pl-2 pr-2 absolute -mr-2" @click="closed">
            X
        </div>
    </div>
    <div class="w-full h-full flex flex-col place-content-center" @click="positiveClick">
        <div  v-if="is_window || is_android" class="flex flex-row w-full h-full space-x-4">
            <div class="h-full flex max-w-xl items-center justify-end">
                <div class="max-w-md rounded-full">
                 <Button :button_text="lang('button.text.prev')" @clicked="prev" :theme="'rounded-full'"/>
                </div>
            </div>
            <div class="w-full h-full bg-white overflow-y-auto">
                <img :src="getSource()" class="w-full h-full"/>
            </div>
            <div class="h-full flex max-w-xl items-center relativ">
                <div class="max-w-md rounded-full">
                 <Button :button_text="lang('button.text.next')" @clicked="next" :theme="'rounded-full'"/>
                </div>
            </div>
        </div>
        <div v-else  class="w-full h-full">
            <iframe src="https://media.pafcpic.net/resource/proposed_amendments.pdf#toolbar=0" frameborder="1" class="w-full h-full" id="ctIframe"  runat="server">
            </iframe>
        </div>
    </div>
</div>
</template>
<script>
import { defineComponent } from "vue";
import { isAndroid,isWinPhone } from 'mobile-device-detect';
import Button from '@/components/controls/ButtonControl.vue';
import mixin from "@/mixin";

export default defineComponent({
    data() {
        return {
            show: true,
            popUp: false,
            count: 1,
            is_window:false,
            is_android:false,
            img:'',
        }
    },
    components:{
        Button
    },
    props:{
        display: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
     mixins: [
      mixin,
    ],
    mounted(){
        let that = this;
        that.is_window = isWinPhone;
        that.is_android = isAndroid;
    },
    methods:{
        closed(){
            this.show = false;
            this.$emit('closedAmendments');
        },
        next() {
            let that = this;
            that.count++;
            if(that.count > 10) {
                that.count = 1;
            }
            that.getSource();
        },
        prev(){
            let that = this;
            that.count--;
            if(that.count <= 0) {
                that.count=10;
            }
            that.getSource();
        },
        getSource(){
            return 'https://media.pafcpic.net/images/proposed-amendments/'+this.count+'.jpg';
        }
    },
    watch: {
        display(n) {
            this.show = n;
            setTimeout(() => {
                this.popUp = n;
            }, 10);
        }
    },
})
</script>
<style scoped>
.bg-translucent {
    background-color: rgba(0, 0, 0, 0.75);
}
.modal-max-h {
    max-height: calc(100vh - 200px);
}
</style>