<template>
    <button :class="['bg-theme-primary text-white p-3 rounded font-bold w-full max-h-12 h-12 ',theme,max_width,{'loading-color':this.loading|| disabled}]" :disabled="this.loading || this.disasbled" @click="clicked">
        <div class="flex justify-center">
            <svg v-show="this.loading" version="1.1" id="L9" class="h-12 w-12 animate-spin absolute -mt-6" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="5px" y="5px"
                viewBox="0 0 100 100" enable-background="new 0 0 0 0" xml:space="preserve">
                    <path fill="#24BF23" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                </path>
            </svg>
            <span v-text="button_text" />
        </div>
    </button>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
    props:{
        button_text:{
            type: String,
            required: true,
        },
        max_width:{
            type:String,
            required:false,
        },
        disabled:{
            type:Boolean,
            required:false,
        },
        theme:{
            type:String,
            required:false,
        },
        loading:{
            type:Boolean,
            required:false,
        }
    },
    data(){
        return {
            disasbled:false,
        }
    },
    methods:{
        clicked(){
            if(this.disabled){
                console.log('');
            } else {
                this.$emit('clicked');
            }
            
        }
    }
})
</script>

<style scoped>
.loading-color{
    background-color: #297D28;
}
</style>