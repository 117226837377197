import services from "../service";

export default {
    getSession(ssuid : string) {
        return services.get('/user/session', {
            ssuid: ssuid,
        });
    },
    login(username: string, password: string) {
        return services.post('/user/authenticate', {
            username: username,
            password: password,
            origin: 2,            
        });
    },
    revertAskOtp(uuid: string){
        return services.put('/user/'+ uuid +'/revert');
    },
    logout(payload: any) {
        return services.post('/user/'+ payload.uuid  +'/logout', {
            hash: payload.hash,
        });
    },
    editUserName(uuid: string, payload: object) {
        return services.put('/user/'+ uuid +'/username',payload);
    },
    editEmail(uuid: string, payload: object) {
        return services.put('/user/'+ uuid +'/email',payload);
    },
    editMobile(uuid: string, payload: object) {
        return services.put('/user/'+ uuid +'/mobile',payload);
    },
    signup(obj : object) {
        return services.post('/user/register/validate', obj);
    },
    signupResend(name : string, email : string, mobile : string) {
        return services.post('/user/register/resend', {
            name: name,
            email: email,
            mobile: mobile,
        });
    },
    createUser(obj : object) {
        return services.post('/user/register', obj);
    },
    forgotPassword(info : string) {
        return services.post('/user/forgot', {
            value: info,
        });
    },
    forgotPasswordResend(uuid : any) {
        return services.post('/user/forgot-password/resend', {
            uuid: uuid,
        });
    },
    updatePassword(payload, uuid : string) {
        return services.put('/user/'+uuid+'/password',payload);
    },
    changePassword(payload : object, uuid : string) {
        return services.put('/user/'+uuid+'/password',payload);
    },
    getuserInfo(uuid : string) {
        return services.get('/user/'+uuid);
    },
    validatePassword(payload: object,uuid : string) {
        return services.post('/user/'+uuid+ '/password/validate', payload);
    },
    deactivate(uuid:string) {
        return services.delete('/user/'+uuid);
    },
    rating(uuid:string,payload) {
        return services.post('/support/'+uuid+'/rate',payload);
    },
    memberAuthenticate(value: string) {
        return services.post('/member/'+value+'/authenticate');
    },
    branchLogin(value) {
        return services.post('/branch/authenticate',{pin:value});
    }

}
