<template>
  <ContainerView :back_ground_color="'bg-white'">
    <ModalControl v-show="show_message_modal" 
      v-bind:display="show_message_modal"
      :error="this.error"
      :success="this.success"
      :note="this.warning" 
      @positiveClick="closed(modal_type)"
      :positiveButton="'OK'"
      :z_value="'z-50'"
      :default_width="'max-w-xl'"
      id='vote_modal'>
      <div class="w-full p-5 flex flex-col text-xl font-bold">
          {{ this.modal_message}}
      </div>
    </ModalControl>
    <ModalControl v-show="show_otp_modal" 
      v-bind:display="show_otp_modal"
      @positiveClick="submitOtp"
      @negativeClick="continueUnverified"
      :positiveButton="'VERIFY'"
      :negativeButton="'CONTINUE AS NOT VERIFIED'"
      :z_value="'z-40'"
      :default_width="'max-w-xl'"
      id='otp_modal'>
     <div class="pb-4 text-sm italic text-left p-2">
          Please enter the one time pin we have sent to your email or mobile number so that we can verify your identity.
      </div>
      <div class="pb-2 font-bold uppercase text-left p-2">
         Enter OTP
      </div>
       <div class="pb-2 font-bold uppercase text-left p-2">
          <TextBoxField @changed="otpUpdate" id="otp" type="text" ref="otp"/>
      </div>
      <div class="pb-2 font-bold uppercase text-left p-2" v-show="show_resend_otp">
         <LinkText emit_triggered='resendOtp' @resendOtp="resendOtp" class="text-theme-primary font-bold" text="Resend"/>
      </div>
    </ModalControl>
    <ModalControl v-show="this.show_registration_modal" 
      v-bind:display="this.show_registration_modal" 
      v-bind:title="lang('registration.title')"
      @closed="closed(modal_type)"
      :z_value="'z-30'"
      :default_width="'max-w-3xl'"
      id='reg_modal'
      closable>
        <div class="w-full flex flex-col space-y-2 p-5" v-if="show_registration_modal">
            <TextBoxField required @changed="registerFields" :initial="reg_info.last_name" id="last_name" type="text" placeholder="Last Name"/>
            <TextBoxField required @changed="registerFields" :initial="reg_info.first_name" id="first_name" type="text" placeholder="First Name"/>
            <TextBoxField required @changed="registerFields" :initial="reg_info.middle_name" id="middle_name" type="text" placeholder="Middle Name"/>.
            <div class="flex flex-row space-x-1">
              <TextBoxField required @changed="registerFields" :initial="reg_info.account_number" id="account_number" type="text" placeholder="Account No." class="-mt-4"/>
              <TextBoxField required @changed="registerFields" :initial="reg_info.serial_number" id="serial_number" type="text" placeholder="Serial No." class="-mt-4"/>
            </div>
            <TextBoxField @changed="registerFields" ref="email"    id="email" type="text" placeholder="Email" />
            <TextBoxField @changed="registerFields" ref="mobile"  :max_length="9"  id="mobile_number" type="mobile" placeholder="Mobile no."/>
            <TextBoxField @changed="registerFields" ref="tin"      id="tin" type="number" placeholder="TIN"/>
            <TextBoxField required @changed="registerFields" ref="username" id="username" type="text" placeholder="Username"/>
            <div class="text-md flex text-start pt-1 text-gray-400 w-full">
                <span v-text="lang('username.instructions')"/>
            </div>
            <form>
            <TextBoxField required strength peekable @changed="registerFields" ref="password" id="password" type="Password" placeholder="Password"/>
             <div class="text-md flex text-start pt-1 text-gray-400 w-full">
                <span v-text="lang('password.instructions')"/>
            </div>
            <TextBoxField peekable @changed="registerFields" ref="confirm_password" id="confirm_password" type="Password" placeholder="Confirm Password"/>
            </form>
            <div class="flex flex-row space-x-2">
            <Checkbox id="agree_ck" class="text-left" @changed="aggree"/>
              <div class="flex flex-row space-x-2 mt-auto mb-auto">
                  <span v-text="'I agree with the'"/>
                  <LinkText emit_triggered='OpenTerms' @OpenTerms="OpenTerms" class="text-theme-primary font-bold" text="End User License Agreement"></LinkText>
                  <span v-text="'and'"/> 
                  <LinkText emit_triggered='OpenPrivacy' @OpenPrivacy="OpenPrivacy" class="text-theme-primary font-bold" text="'Privacy Policy'"></LinkText>
              </div>
            </div>
            <Button :button_text="'REGISTER'" @clicked="register" :disabled="this.reg_button_disable"/>
        </div>
    </ModalControl>
    <ModalControl v-show="this.show_poll_modal" 
      v-bind:display="this.show_poll_modal" 
      :z_value="'z-50'"
      v-bind:title="lang('amendments.title')"
      @closed="closed(modal_type)"
      @positiveClick="vote"
      @readAmendments="showAmendments"
      :positiveButton="lang('submit')"
      :amendment="lang('view.amendments')"
      :default_width="'max-w-2xl'"
      id='vote_modal'
      ref='pollModal'
      closable>
      <div class="w-full p-5 flex flex-col space-y-2 text-left">
        <span class="text-lg" v-text="lang('letter.greetings')" />
        <span class="text-lg mb-3" v-text="lang('letter.first.para')" />
        <LinkText emit_triggered='OpenTerms' @OpenTerms="show_pdf=true" class="text-theme-primary font-bold text-lg" :text="lang('view.amendments')"></LinkText>
        <span class="text-lg font-bold mt-3" v-text="lang('letter.second.para')" />
        <span class="text-lg" v-text="lang('letter.casting.vote')" />
         <span class="text-lg" v-text="lang('Very truly yours,')" />
         <img src="https://media.pafcpic.net/images/misc/paredes-signature.jpg" class="modal_max_width -ml-1 -mb-2" />
          <span class="text-lg font-bold" v-text="lang('BGEN FRANCISCO M. PAREDES (RET)')" />
          <span class="text-lg" v-text="lang('Chairperson of the Board')" />
         <div class="border border-black w-full mb-2"/>
         <span class="text-lg" v-text="lang('letter.acknowledging.para')" />
      </div>
    </ModalControl>
    <ModalControl v-show="show_qr_reader" 
      v-bind:display="show_qr_reader"
      @closed="closed(modal_type)"
      @positiveClick="vote"
      :default_width="'max-w-2xl'"
      :z_value="'z-50'"
      id='vote_modal'
      closable>
      <div class="w-full p-5 flex flex-col">
        <StreamBarcodeReader 
        v-if="show_qr_reader"  
        @decode="onDecode"
        @loaded="onLoaded"
        />
      </div>
    </ModalControl>
    <ModalControl v-show="show_search_field" 
      v-bind:display="show_search_field"
      @closed="closed(modal_type)"
      :default_width="'max-w-xl'"
      title="SEARCH USER"
      :z_value="'z-50'"
      id='vote_modal'
      closable>
      <div class="w-full p-5 flex flex-col ">
        <div class="flex flex-col p-2 space-y-2">
          <TextBoxField uppercase @changed="changeAccountNo" ref="Afield" id="Afield" type="text" :placeholder="lang('by.account.number')"/>
        </div>
        <div class="border border-gray-400 m-5 -ml-3 -mr-3" />
        <div class="flex flex-col p-2 space-y-2">
          <TextBoxField uppercase @changed="changeSerialNo" ref="Sfield" id="Sfield" type="text" :placeholder="lang('by.serial.number')"/>
        </div>
      </div>
    </ModalControl>
    <ModalControl v-show="show_identifier" 
      v-bind:display="show_identifier"
      @closed="closed(modal_type)"
      :default_width="'max-w-2xl'"
      :z_value="'z-50'"
      id='vote_modal'
      >
      <div class="w-full flex flex-col p-2 space-y-2">
          <span class="self-start pb-4 font-bold text-2xl" v-text="'VERIFY YOUR ACCOUNT'"/>
          <div class="flex flex-col w-full">
            <div class="flex w-45 grow-0">
                <div class="rounded-full flex border-4 bg-theme-primary border-theme-secondary  m-auto w-40 h-40">
                    <Icon id="pencil" class="self-center m-auto" source="icons/papsi_profile.svg" :width="100" />
                </div>
            </div>
            <div class="pt-2 w-full flex flex-col grow text-left ">
                <div class="flex flex-col ">
                  <div class="pb-6 text-sm">
                    <div class="border border-gray-400 bg-white flex flex-row border-b-0">
                        <div class="p-2 font-bold contact-w border-r border-r-gray-400 uppercase">NAME</div>
                        <div class="p-2 flex-grow text-gray-500 truncate" v-text="reg_info.name"></div>
                    </div>
                    <div class="border border-gray-400 bg-white flex flex-row border-b-0">
                        <div class="p-2 font-bold contact-w border-r border-r-gray-400 uppercase">ACCOUNT NO.</div>
                        <div class="p-2 flex-grow text-gray-500 truncate" v-text="reg_info.account_number"></div>
                    </div>
                    <div class="border border-gray-400 bg-white flex flex-row ">
                        <div class="p-2 font-bold contact-w border-r border-r-gray-400 uppercase">SERIAL NO.</div>
                        <div class="p-2 flex-grow text-gray-500 truncate" v-text="reg_info.serial_number"></div>
                    </div>
                </div>
                </div>
            </div>
          </div>
          <Button @clicked="proceed" :button_text="lang('PROCEED')"/>
          <Button @clicked="remove" :theme="'bg-red-600'" :button_text="lang('NOT ME')"/>
      </div>
    </ModalControl>
    <LoadingControl v-show="loading" :loading="loading"/>
    <AmendmentView :display="show_pdf" @closedAmendments="closedAmendments" />
    <div class="w-full h-full flex flex-col p-5 text-left z-35">
      <div class="mt-auto mb-auto w-full flex flex-col space-y-5 items-center">
           <!-- <CardView :max_width="'max-w-2xl p-2 h-72 bg-theme-secondary'" @click="search">
            <Icon id="pencil" class="self-center" source="icons/search_user.svg" :width="150" />
            <span v-text="lang('search')" class="text-white font-bold text-5xl self-center"/>
          </CardView> -->
          <CardView :max_width="'max-w-2xl p-2 h-72 '" :background="'bg-green-700'" @click="showQr">
            <Icon id="pencil" class="self-center" source="icons/qr.svg" :width="150" />
            <span v-text="lang('qr.card.label')" class="text-white font-bold text-5xl self-center"/>
          </CardView>
      </div>
      <!-- <div class="text-theme-primary rounded p-5 font-bold text-3xl xs:space-y-2 flex flex-row xs:flex-col bg-white">
        <div class="flex flex-row w-full  space-x-2">
          <Icon id="pencil" class="self-center" source="icons/papsi_location_icon.svg" :width="35" />
          <span v-text="this.branch_name" />
        </div>
        <div class="flex flex-row w-full space-x-2 justify-end xs:justify-start" @click="logout">
          <Icon id="pencil" class="self-center" source="icons/papsi_logout_icon.svg" :width="35" />
          <span v-text="'LOGOUT'" class="text-red-500"/>
        </div>
      </div> -->
    </div>
  </ContainerView>
</template>

<script>
import { defineComponent } from 'vue';
import { StreamBarcodeReader } from "vue-barcode-reader";
import { isAndroid,isWinPhone } from 'mobile-device-detect';
import ContainerView from '@/components/sections/ContainerView.vue';
import TextBoxField from "@/components/controls/TextBoxField.vue";
import member from "@/services/Member";
import User from "@/services/User";
import Button from "@/components/controls/ButtonControl.vue";
import Checkbox from "@/components/controls/CheckBox.vue";
import CardView from "@/components/controls/CardView.vue";
import ModalControl from "@/components/controls/ModalControl.vue";
import AmendmentView from "@/components/sections/AmendmentView.vue";
import Icon from "@/components/controls/Icon.vue";
import LoadingControl from "@/components/controls/LoadingControl.vue";
import LinkText from "@/components/controls/LinkText.vue";
import election from "@/services/Election";
import OTP from "@/services/OTP";
import mixin from "@/mixin";

export default defineComponent({
    components:{
      ContainerView,
      TextBoxField,
      ModalControl,
      AmendmentView,
      LoadingControl,
      Icon,
      StreamBarcodeReader,
      CardView,
      Button,
      LinkText,
      Checkbox
    },
    mounted() {
        let that = this;
        // const cookie = that.$cookies;
        let queryString = window.location.search;
        let urlParams = new URLSearchParams(queryString);
        let val ='';
        // this.otpTest();
        that.android = isAndroid;
        that.window_phone = isWinPhone;
        // that.show_otp_modal= true;
        // if(!cookie.get('user') || cookie.get('user').branch_name =='') {
        //   that.$router.push('/');
        // }else{
        //   that.branch_name = cookie.get('user').branch_name;
         
        // }
         if( urlParams.has('qr') ){
            val = urlParams.get('qr');
            that.onDecode(val);
          }
        
    },
     data(){
      return {
        search_value:'',
        show_registration_modal:false,
        show_poll_modal:false,
        show_qr_reader:false,
        show_search_field:false,
        show_identifier:false,
        show_message_modal:false,
        show_otp_modal:false,
        show_resend_otp:false,
        error:false,
        otp:'',
        can_vote:false,
        reg_button_disable: true,
        modal_type:1,
        success:false,
        warning:false,
        modal_message:'',
        show_pdf:false,
        android:false,
        window_phone:false,
        timerEnabled:false,
        branch_name:'',
        uuid:'',
        hash:'',
        timerCount:0,
        reg_info:{
            name: null,
            last_name:null,
            middle_name:null,
            first_name:null,
            account_number:null,
            serial_number:null,
            email:null,
            mobile_number:null,
            username:null,
            password:null,
            confirm_password:null,
            aggree:false,
            tin:null,
            pin:null,
            score:0,
        },
        loading:false,
      }
    },
    mixins: [
      mixin,
    ],
    methods:{
      search() {
        this.show_search_field = true;
        // this.show_registration_modal=true;
        // this.show_poll_modal=true;
      },
      
      resendOtp() {
        let that = this;
        that.loading = true;
        that.show_resend_otp = false;
        that.timerEnabled= true;
        that.timerCount = 120;
        OTP.requestOtp(
            {
              type:2, 
              email:that.reg_info.email, 
              mobile:that.reg_info.mobile_number!=''?that.reg_info.mobile_number:'', 
              name :that.reg_info.first_name, 
              pin:that.reg_info.pin,
            }
          ).then(function(response) {
              that.reg_info.pin = response.data.data.pin;
              that.loading = true;
              // that.show_registration_modal = false;
              that.loading= false;
              that.show_otp_modal = true;
              
          }).catch(function() {
              that.loading= false;
              that.modal_type = 1;
          });
      },
      submitOtp() {
        let that = this;
        that.loading =true;
        OTP.validate({otp:that.otp,type:5,email:that.reg_info.email,mobile:that.reg_info.mobile_number==''?'':that.reg_info.mobile_number}).then(function(){
          // that.show_registration_modal = false;
          // that.show_otp_modal= false;
          // that.$refs.otp.clearText();
          // that.createUser();
          election.register(that.uuid).then(function() {
              that.loading = false;
              that.success = true;
              that.show_message_modal = true;
              that.modal_message = "Thank you! for Registering";
              that.modal_type = 1;
            }).catch(function(error) {
              that.loading = false;
              that.error = true;
              that.show_message_modal = true;
              that.modal_message = error.response.data.message;
              that.modal_type = 1;
            });
          that.otp = '';
        }).catch(function(error) {
          that.error = true;
          that.show_message_modal= true;
          that.modal_message = error.response.data.message;
          that.loading = false;
        });
      },
      otpUpdate(e) {
        let that = this;
        that.otp = e.value;
      },
      
      proceed(){
        // let cookie = this.$cookies;
        let that = this;
        const ref = that.$refs;
        ref.pollModal.clearStatus();
        that.show_identifier = false;
        // that.can_vote = true;
        // console.log(that.uuid,that.can_vote);
        if(that.uuid != null && that.can_vote) {
          that.getUserInfo(that.uuid);
        } if(that.uuid && !that.can_vote) {
           that.loading = true;
           election.register(that.uuid).then(function() {
              that.loading = false;
              that.success = true;
              that.show_message_modal = true;
              that.modal_message = "Thank you! for Registering";
              that.modal_type = 1;
            }).catch(function(error) {
              that.loading = false;
              that.error = true;
              that.show_message_modal = true;
              that.modal_message = error.response.data.message;
              that.modal_type = 1;
            });
        }
        if(!that.uuid) {
          that.modal_type = 1;
          that.show_registration_modal = true;
        }
      },
      getScore(n) {
       this.reg_info.score = n;
      },
      remove() {
        this.show_identifier = false;
      },
      OpenTerms() {
          this.$emit('OpenTerms');
      },
      OpenPrivacy() {
        this.$emit('OpenPrivacy');
      },
      submitSearch(type) {
        let that = this;
        // const store = this.$store;
        // const state = store.state;
        that.loading = true;
        that.show_search_field = false;
        if(type==1) {
          member.getMemberInfoByAccountNumber(that.search_value)
          .then(function(response) {
            let data = response.data;
            // console.log(data);
            if(data.success) {
              that.search_value='';
              that.getByHash(data.data.key);
            }
          })
          .catch(function(error) {
            that.error = true;
            that.show_message_modal = true;
            that.modal_message = error.response.data.message;
            that.loading = false;
            that.modal_type = 1;
            that.search_value='';
        });
        } else {
            member.getMemberInfoBySerialNumber(that.search_value)
            .then(function(response) {
            let data = response.data;
            // console.log(data);
            if(data.success) {
                that.search_value='';
                that.getByHash(data.data.key);
            }
          })
          .catch(function(error) {
            that.error = true;
            that.show_message_modal = true;
            that.modal_message = error.response.data.message;
            that.loading = false;
            that.modal_type = 1;
            that.search_value='';
          });
        }
      },
      createUser() {
        let payload;
        let that = this;
        let cookie = that.$cookies;
        that.loading= true;
        payload = {
           first_name:that.reg_info.first_name, 
           last_name:that.reg_info.last_name, 
           middle_name:that.reg_info.middle_name, 
           account_no:that.reg_info.account_number, 
           serial_no:that.reg_info.serial_number, 
           email:that.reg_info.email, 
           mobile:that.reg_info.mobile_number==''?'':that.reg_info.mobile_number, 
           username:that.reg_info.username,
           password1:that.reg_info.password, 
           password2:that.reg_info.confirm_password,
           tin:that.reg_info.tin,
           verified:1
        }
        User.createUser(payload).then(function(create) {
                that.uuid = create.data.data.uuid;
                that.reg_info.name = null;
                that.reg_info.last_name = null;
                that.reg_info.first_name = null;
                that.reg_info.middle_name = null;
                that.reg_info.account_number = null;
                that.reg_info.serial_number = null;
                that.reg_info.mobile_number =null;
                that.reg_info.password = null;
                that.reg_info.confirm_password = null;
                that.reg_info.email =null;
                that.reg_info.tin=null;
                that.reg_info.aggree = false;
              User.getuserInfo(that.uuid).then(function(getuser) {
                  const getuser_response = getuser.data.data;
                  
                  that.hash = getuser_response.key;
                  that.uuid = getuser_response.uuid;

                  if(getuser_response.election.can_vote) {
                        that.modal_type = 1;
                        that.loading =false;
                        that.proceed();
                  }
                  if(!getuser_response.election.can_vote) {
                    election.register(that.uuid, cookie.get('user').branch_code).then(function() {
                        that.success = true;
                        that.modal_type = 1;
                        that.show_message_modal = true;
                        that.modal_message = "You've Successfully Registered";
                        that.loading = false;
                    }).catch(function(error) {
                        that.loading= false;
                        that.modal_type = 1;
                        that.error = true;
                        that.show_message_modal = true;
                        that.modal_message = error.response.data.message;
                    });
                  }  
              }).catch(function(error) {
                  that.loading= false;
                  that.error = true;
                  that.modal_type = 2;
                  that.show_message_modal = true;
                  that.modal_message = error.response.data.message;
              });
        }).catch(function(error) {
          that.loading= false;
          that.modal_type = 2;
          that.error = true;
          that.show_message_modal = true;
          that.modal_message = error.response.data.message;
        });
      },
      otpTest(){
        let that = this;
        that.timerEnabled= true;
        that.timerCount =10;
        OTP.requestOtp(
            {
              type:2, 
              // email:payload.email, 
              mobile:'+639558262522', 
              name :that.reg_info.first_name+'luke', 
              pin:'',
            }
          ).then(function() {
              // that.show_registration_modal = false;
              that.loading= false;
              that.show_otp_modal = true;
             
              
          }).catch(function() {
              that.loading= false;
              that.modal_type = 1;
          });
      },

      register() {
        let payload;
        let that = this;
        that.loading = true;
        // that.show_registration_modal = false;
        // this.show_poll_modal = true;
        payload = {
           first_name:that.reg_info.first_name, 
           last_name:that.reg_info.last_name, 
           middle_name:that.reg_info.middle_name, 
           account_no:that.reg_info.account_number, 
           serial_no:that.reg_info.serial_number, 
           email:that.reg_info.email, 
           mobile:that.reg_info.mobile_number==''?'':that.reg_info.mobile_number, 
           username:that.reg_info.username,
           password1:that.reg_info.password, 
           password2:that.reg_info.confirm_password,
           tin:that.reg_info.tin,
           verified:1,
        }
        User.signup(payload).then(function() {
          that.timerEnabled= true;
          that.timerCount =120;
          OTP.requestOtp(
            {
              type:2, 
              email:payload.email, 
              mobile:payload.mobile, 
              name :that.reg_info.first_name, 
              pin:'',
            }
          ).then(function(response) {
            // console.log(response.data.data);
              that.reg_info.pin = response.data.data.pin;
              // that.show_registration_modal = false;
              that.loading= false;
              that.show_otp_modal = true;
          }).catch(function() {
              that.loading= false;
              that.modal_type = 1;
          });
        }).catch(function(error) {
          that.loading= false;
          that.modal_type = 2;
          that.error = true;
          that.show_message_modal = true;
          that.modal_message = error.response.data.message;
          
        });
      },
      vote(n)
      {
        let that= this;
        const cookie = that.$cookies;
        const ref = that.$refs;
        let payload = {
              client:'3',
              vote:n,
              br_code:cookie.get('user').branch_code,
              key:that.hash,
        }
        that.loading = true;
        that.show_poll_modal = false;
        ref.pollModal.clearStatus();
        election.register(that.uuid,cookie.get('user').branch_code).then(function(){
                election.pollVote(that.uuid,payload).then(function(){
                    that.success = true;
                    that.modal_message = "Your vote has been successfully cast. Thank you for participating in our Special General Assembly";
                    that.show_message_modal = true;
                    that.loading = false;
                }).catch(function(error) {
                    that.error = true;
                    that.loading = false;
                    that.show_message_modal = true;
                    that.modal_type = 1;
                    that.modal_message = error.response.data.message;
                });
          }).catch(function(error) {
              that.loading = false;
              that.error = true;
              that.show_message_modal = true;
              that.modal_message = error.response.data.message;
              that.modal_type = 1;
          });
        
        
      },
      continueUnverified() {
        let payload;
        let that = this;
        let cookie = that.$cookies;
        that.loading= true;
        payload = {
           first_name:that.reg_info.first_name, 
           last_name:that.reg_info.last_name, 
           middle_name:that.reg_info.middle_name, 
           account_no:that.reg_info.account_number, 
           serial_no:that.reg_info.serial_number, 
           email:that.reg_info.email, 
           mobile:that.reg_info.mobile_number==''?'':that.reg_info.mobile_number, 
           username:that.reg_info.username,
           password1:that.reg_info.password, 
           password2:that.reg_info.confirm_password,
           tin:that.reg_info.tin,
           verified:0,
        }
        User.createUser(payload).then(function(create) {
                that.uuid = create.data.data.uuid;
                that.reg_info.name = null;
                that.reg_info.last_name = null;
                that.reg_info.first_name = null;
                that.reg_info.middle_name = null;
                that.reg_info.account_number = null;
                that.reg_info.serial_number = null;
                that.reg_info.mobile_number =null;
                that.reg_info.password = null;
                that.reg_info.confirm_password = null;
                that.reg_info.email =null;
                that.reg_info.tin=null;
                that.reg_info.aggree = false;
                that.show_otp_modal = false;
              User.getuserInfo(that.uuid).then(function(getuser) {
                  const getuser_response = getuser.data.data;
                  
                  that.hash = getuser_response.key;
                  that.uuid = getuser_response.uuid;

                  if(getuser_response.election.can_vote) {
                        that.modal_type = 1;
                        that.loading =false;
                        that.proceed();
                  }
                  if(!getuser_response.election.can_vote) {
                    election.register(that.uuid, cookie.get('user').branch_code).then(function() {
                        that.success = true;
                        that.modal_type = 1;
                        that.show_message_modal = true;
                        that.modal_message = "Thank you! for Registering";
                        that.loading = false;
                    }).catch(function(error) {
                        that.loading= false;
                        that.modal_type = 1;
                        that.error = true;
                        that.show_message_modal = true;
                        that.modal_message = error.response.data.message;
                    });
                  }  
              }).catch(function(error) {
                  that.loading= false;
                  that.error = true;
                  that.modal_type = 2;
                  that.show_message_modal = true;
                  that.modal_message = error.response.data.message;
              });
        }).catch(function(error) {
          that.loading= false;
          that.modal_type = 2;
          that.error = true;
          that.show_message_modal = true;
          that.modal_message = error.response.data.message;
        });
      },
      showAmendments() {
         this.show_pdf = true;
      },
      showQr() {
        if( isAndroid || isWinPhone ) {
          window.mobile.openQr();
        } else {
          this.show_qr_reader = true;
        }
      },
      closedAmendments(){
        this.show_pdf = false;
      },
      clearFields() {
        let ref = this.$refs;
        ref.email.clearText();
        ref.mobile.clearText();
        ref.tin.clearText();
        ref.username.clearText();
        ref.password.clearText(); 
        ref.confirm_password.clearText();
      },
      getMemberInfo(value) {
        let that = this;
        that.loading = true;
        member.getMemberInfoByHash(value).then(function(response) {
          let data = response.data.data;
          // console.log(data);
          if(response.data.success) {
                that.loading = false;
                that.reg_info.name = data.last_name +' '+ data.first_name +' '+ data.middle_name;
                that.reg_info.last_name = data.last_name;
                that.reg_info.first_name = data.first_name;
                that.reg_info.middle_name = data.middle_name;
                that.reg_info.account_number = data.account_no;
                that.reg_info.serial_number = data.serial;
                that.hash = data.hash;
                that.uuid = data.uuid;
                that.can_vote = data.can_vote;
                that.modal_type =1;
                that.show_identifier = true;
          }
        })
        .catch(function(error) {
          that.loading= false;
          that.error = true;
          that.show_message_modal = true;
          that.modal_type =1;
          that.modal_message = error.response.data.message;
        });
      },
      getUserInfo(){
        let that = this;
        that.loading = true;
        User.getuserInfo(that.uuid).then(function(getuser) {
                  const getuser_response = getuser.data.data;

                  that.reg_info.name = getuser_response.info.last_name + ' ' + getuser_response.info.first_name + ' '+ getuser_response.info.middle_name ;
                  that.reg_info.last_name = getuser_response.info.last_name;
                  that.reg_info.first_name = getuser_response.info.first_name;
                  that.reg_info.middle_name = getuser_response.info.middle_name;
                  that.reg_info.account_number = getuser_response.info.account_no;
                  that.reg_info.serial_number = getuser_response.info.serial_no;
                  that.reg_info.email = getuser_response.account.email;
                  that.reg_info.mobile_number = getuser_response.account.mobile;

                  that.hash = getuser_response.key;
                  that.uuid = getuser_response.uuid;
                  that.can_vote = getuser_response.election.can_vote;
                  if(getuser_response.election.registered){
                       election.register(that.uuid).then(function(response) {
                        that.success = true;
                        that.modal_type = 1;
                        that.show_message_modal = true;
                        that.modal_message = response.data.data.message;
                        that.loading = false;
                    }).catch(function(error) {
                        that.loading= false;
                        that.modal_type = 1;
                        that.error = true;
                        that.show_message_modal = true;
                        that.modal_message = error.response.data.message;
                    });
                  }else{
                     OTP.requestOtp(
                    {
                      type:5, 
                      email:that.reg_info.email, 
                      mobile:that.reg_info.mobile_number, 
                      name :that.reg_info.first_name, 
                      pin:'',
                    }
                  ).then(function(response) {

                     election.register(that.uuid).then(function(response) {
                        that.success = true;
                        that.modal_type = 1;
                        that.show_message_modal = true;
                        that.modal_message = response.data.data.message;
                        that.loading = false;
                    }).catch(function(error) {
                        that.loading= false;
                        that.modal_type = 1;
                        that.error = true;
                        that.show_message_modal = true;
                        that.modal_message = error.response.data.message;
                    });
                    // console.log(response.data.data);
                      that.reg_info.pin = response.data.data.pin;
                      // that.show_registration_modal = false;
                      // that.show_otp_modal = true;
                  }).catch(function() {
                      that.loading= false;
                      that.modal_type = 1;
                  });
                  }
                 
                  // that.show_identifier = true;
              }).catch(function(error) {
                  that.loading= false;
                  that.error = true;
                  that.modal_type =1;
                  that.show_message_modal = true;
                  that.modal_message = error.response.data.message;
              });
      },
      getByHash(value) {
        let that = this;
        User.memberAuthenticate(value).then(function(response) {
          let data = response.data.data;
          if(response.data.success) {
              that.loading = false;
              that.reg_info.name = data.name;
              that.reg_info.last_name = data.last_name;
              that.reg_info.first_name = data.first_name;
              that.reg_info.middle_name = data.middle_name;
              that.reg_info.account_number = data.account_no;
              that.reg_info.serial_number = data.serial_no;
              that.hash = data.hash;
              that.uuid = data.uuid;
              that.modal_type =1;
              if(that.uuid) {
                that.getUserInfo();
              } else {
                that.getMemberInfo(value);
              }
              
          }
           
        })
        .catch(function(error) {
          let message =error.response.data.message;
          // console.log(error);
          if(message == "Member is still not registered.") {
            // console.log('Not registered');
            that.modal_type =1;
            that.getMemberInfo(value);
          } 
          if(message == "Member is not eligible to vote.") {
            that.modal_type =1;
            that.getMemberInfo(value);
          } else if(message != "Member is still not registered." && message != "Member is not eligible to vote.") {
            that.loading = false;
            that.error = true;
            that.show_message_modal = true;
            that.modal_message = error.response.data.message;
            that.modal_type = 1;
          }
        });
      },
      onDecode(value) {
        let that = this;
        that.show_qr_reader=false;
        that.loading = true;
        that.getMemberInfo(value);
        // User.memberAuthenticate(value).then(function(response) {
        //   let data = response.data.data;
        //   if(response.data.success) {
        //       that.loading = false;
        //       // that.reg_info.name = data.name;
        //       // that.reg_info.last_name = data.last_name;
        //       // that.reg_info.first_name = data.first_name;
        //       // that.reg_info.middle_name = data.middle_name;
        //       // that.reg_info.account_number = data.account_no;
        //       // that.reg_info.serial_number = data.serial_no;
        //       // that.uuid = data.uuid;
        //       // that.hash = data.hash;
              
        //   }
           
        // })
        // .catch(function(error) {
        //   console.log(error);
        //   if(error.response.data.message === "Member is still not registered.") {
        //     that.getMemberInfo(value);
        //   } if(error.response.data.message === "Member is not eligible to vote.") {
        //     that.getMemberInfo(value);
        //   } else if(error.response.data.message != "Member is still not registered." && error.response.data.message != "Member is not eligible to vote.") {
        //     that.loading = false;
        //     that.error = true;
        //     that.show_message_modal = true;
        //     that.modal_message = error.response.data.message;
        //     that.modal_type = 1;
        //   }
        // });
      },
      // onLoaded(value){
      //   console.log(value);
      // },
      registerFields(e) {
        let that = this;
        let obj = that.reg_info;
        obj[e.key] = e.value;

        if(that.reg_info.first_name && 
          that.reg_info.last_name && 
          that.reg_info.username &&
          that.reg_info.password &&
          that.reg_info.confirm_password &&
          (that.reg_info.email ||
          that.reg_info.mobile_number) &&
          (that.reg_info.account_number || 
          that.reg_info.serial_number) &&
          that.reg_info.aggree) {
            that.reg_button_disable=false; 
        }else{
          that.reg_button_disable=true; 
        }
      },
      closePdf() {
        this.show_pdf = false;
      },
      aggree(n) {
        let that = this;
        that.reg_info.aggree = n;
         if(that.reg_info.first_name && 
          that.reg_info.last_name && 
          that.reg_info.username &&
          that.reg_info.password &&
          that.reg_info.confirm_password &&
          (that.reg_info.email ||
          that.reg_info.mobile_number) &&
          (that.reg_info.account_number || 
          that.reg_info.serial_number) &&
          that.reg_info.aggree) {
            that.reg_button_disable=false; 
        }else{
          that.reg_button_disable=true; 
        }
      },
      logout(){
        const cookie = this.$cookies;
        cookie.remove('user');
        this.$router.push('/');
      },
      closed(type){
        let that = this;
        // console.log(type);
        if(type == 2) {
          that.show_registration_modal = true;
          that.show_search_field = false;
          that.show_poll_modal = false;
          that.show_qr_reader = false;
          that.show_message_modal = false;
          that.error = false;
          that.success = false;
          that.warning = false;
        } if(type == 3) {
          that.show_poll_modal = true;
          that.show_registration_modal = false;
          that.show_search_field=false;
          that.show_qr_reader = false;
          that.show_message_modal = false;
          that.error = false;
          that.success = false;
          that.warning = false;
        } if(type == 1) {
          that.reg_info.last_name = '';
          that.reg_info.first_name = '';
          that.reg_info.middle_name = '';
          that.reg_info.account_number = '';
          that.reg_info.serial_number = '';
          that.reg_info.email = '';
          that.reg_info.mobile_number = '';
          that.uuid = '';
          that.show_registration_modal = false;
          that.show_search_field=false;
          that.show_poll_modal = false;
          that.show_qr_reader = false;
          that.show_message_modal = false;
          that.error = false;
          that.success = false;
          that.warning = false;
        }
      },
      changeAccountNo(e) {
          let that = this;
          that.search_value = e.value;
            if(e.value != undefined){
              if(that.search_value.length == 6) {
                that.submitSearch(1);
                that.$refs.Afield.clearText();
              }
          }
         
      },
      changeSerialNo(e) {
          let that = this;
          that.search_value = e.value;
          if(e.value != undefined){
            if(that.search_value.length == 6) {
              that.submitSearch(2);
              that.$refs.Sfield.clearText();
            }
          }
      },
    },
  watch: {
    timerCount: {
      handler(value) {
        if (value > 0 && this.timerEnabled) {
          this.show_resend_otp = false;
          setTimeout(() => {
            this.timerCount = this.timerCount - 1;
            // console.log(this.timerCount);
          }, 1000);
        } else {
          this.show_resend_otp = true;
          this.timerEnabled = false; // run your function here
        }
      },
      immediate: true
    },
  },
})
</script>
<style scoped>
.modal_max_width{
  max-width: 200px;
}
.contact-w {
    width: 100px !important;
    min-width: 120px !important;
}
</style>
