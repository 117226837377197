import services from "../service";

export default {
    register(uuid : string) {
        return services.post('/election/'+ uuid+ '/register');
    },
    startVoting(uuid : string){
        return services.post('/election/'+uuid+'/start');
    },
    pollVote(uuid : string ,payload: any){
        payload.client = 3;
        return services.post('/election/poll/'+uuid,payload);
    },
    submitVote(uuid:string,payload: any) {
        return services.post('/election/'+uuid+'/vote', payload);
    },
    getBallot(ballot) {
        return services.get('/election/ballot/'+ballot);
    },
    getBOD() {
        return services.get('/election/candidates/bod');
    },
    getAudit() {
        return services.get('/election/candidates/audit');
    },
    getElecom() {
        return services.get('/election/candidates/elecom');
    },
    attachOtp(uuid: string,otp:string) {
        return services.put('/election/'+uuid,{otp:otp});
    },
    mockParticipate(uuid: string,payload) {
        return services.post('/election/'+uuid+'/mock',{action:payload});
    },
    getSchedule(uuid : string) {
        return services.get('/election/'+uuid);
    },
    verify(uuid : string, otp : string) {
        return services.post('/election/otp', {
            uuid: uuid,
            otp: otp,
        });
    },
    vote(uuid : string, votes : string) {
        return services.post('/election/vote', {
            uuid: uuid,
            votes: votes,
        });
    },
    resend(uuid : string) {
        return services.post('/election/otp/resend', {
            uuid: uuid,
        });
    },
    saveRemarks(payload : any) {
        return services.post('/election/remarks', payload);
    },
}
